<template>
    <div class="change-password-container">
        <div class="card">
            <h2 class="title">Alterar Senha</h2>
            <form @submit.prevent="handleSubmit" class="form">
                <div class="field">
                    <label for="oldPassword">Senha Atual</label>
                    <Password 
                        id="oldPassword"
                        v-model="form.oldPassword" 
                        :feedback="false"
                        :toggleMask="true"
                        :class="{'p-invalid': errors.oldPassword}"
                        inputClass="w-full"
                    />
                    <small class="p-error" v-if="errors.oldPassword">{{ errors.oldPassword }}</small>
                </div>

                <div class="field">
                    <label for="password">Nova Senha</label>
                    <Password 
                        id="password"
                        v-model="form.password" 
                        :feedback="true"
                        :toggleMask="true"
                        :class="{'p-invalid': errors.password}"
                        inputClass="w-full"
                    />
                    <small class="p-error" v-if="errors.password">{{ errors.password }}</small>
                </div>

                <div class="field">
                    <label for="confirmPassword">Confirmar Nova Senha</label>
                    <Password 
                        id="confirmPassword"
                        v-model="form.confirmPassword" 
                        :feedback="false"
                        :toggleMask="true"
                        :class="{'p-invalid': errors.confirmPassword}"
                        inputClass="w-full"
                    />
                    <small class="p-error" v-if="errors.confirmPassword">{{ errors.confirmPassword }}</small>
                </div>

                <Button 
                    type="submit" 
                    label="Alterar Senha" 
                    :loading="loading"
                />
            </form>
        </div>
    </div>
</template>

<script>
import { changePassword } from '@/services/api';
import Password from 'primevue/password';
import Button from 'primevue/button';

export default {
    name: 'ChangePassword',
    components: {
        Password,
        Button
    },
    data() {
        return {
            form: {
                oldPassword: '',
                password: '',
                confirmPassword: ''
            },
            errors: {
                oldPassword: '',
                password: '',
                confirmPassword: ''
            },
            loading: false
        }
    },
    methods: {
        validateForm() {
            let isValid = true;
            this.errors = {
                oldPassword: '',
                password: '',
                confirmPassword: ''
            };

            if (!this.form.oldPassword) {
                this.errors.oldPassword = 'Senha atual é obrigatória';
                isValid = false;
            }

            if (!this.form.password) {
                this.errors.password = 'Nova senha é obrigatória';
                isValid = false;
            } else if (this.form.password.length < 4) {
                this.errors.password = 'A senha deve ter no mínimo 4 caracteres';
                isValid = false;
            }

            if (!this.form.confirmPassword) {
                this.errors.confirmPassword = 'Confirmação de senha é obrigatória';
                isValid = false;
            } else if (this.form.password !== this.form.confirmPassword) {
                this.errors.confirmPassword = 'As senhas não coincidem';
                isValid = false;
            }

            return isValid;
        },
        async handleSubmit() {
            if (!this.validateForm()) return;

            this.loading = true;
            try {
                const response = await changePassword(this.form);

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: response.data.message || 'Senha alterada com sucesso',
                    life: 3000
                });

                // Limpar formulário
                this.form = {
                    oldPassword: '',
                    password: '',
                    confirmPassword: ''
                };
            } catch (error) {
                const errorMessage = error.response?.data?.message || 'Não foi possível alterar a senha';
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: errorMessage,
                    life: 3000
                });
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
.change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem;
}

.card {
    width: 100%;
    max-width: 450px;
    padding: 2rem;
    border-radius: 10px;
    background: var(--surface-card);
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.title {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--text-color);
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.field label {
    font-weight: 500;
    color: var(--text-color);
}

:deep(.p-password) {
    width: 100%;
}

:deep(.p-password input) {
    width: 100%;
}

:deep(.p-button) {
    width: 100%;
}

.p-error {
    color: var(--red-500);
    font-size: 0.875rem;
}
</style>
