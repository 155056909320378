export default {
    methods: {
        success(message,life = 3000, summary = null){
             this.$toast.removeAllGroups();
             this.$toast.add({severity:'success', summary: summary, detail:message, life: life});
        },
        info(message,life = 3000, summary = null){
             this.$toast.removeAllGroups();
             this.$toast.add({severity:'info', summary: summary, detail:message, life: life});
        },
        warn(message,life = 3000, summary = null){
             this.$toast.removeAllGroups();
             this.$toast.add({severity:'warn', summary: summary, detail:message, life: life});
        },
        error(message,life = 3000, summary = null){
             this.$toast.removeAllGroups();
             this.$toast.add({severity:'error', summary: summary, detail:message, life: life});
        },
        swNotification(message,life = 3000, summary = null){
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'updating', summary: summary, detail:message, life: life});
        },
        divideCanvas(canvas) {
            const canvasHeight = canvas.height;
            const maxCanvasSize = 1400
            const ctx = canvas.getContext('2d')
            // console.log('Size: ' + canvasHeight);
            
            let divideHeights = []
            let height = 0
            
            while (height < canvasHeight){
                let cutHeight = this.findBlankSpace(canvas,ctx,maxCanvasSize+height);
                // ctx.beginPath();ctx.moveTo(0, cutHeight);ctx.lineTo(canvas.width, cutHeight);ctx.strokeStyle = "#FF0000";ctx.stroke();
                divideHeights.push(cutHeight)
                
                height = cutHeight

                if (canvasHeight - cutHeight < maxCanvasSize) {
                    height = canvasHeight
                    divideHeights.push(height)
                }
            }
            let canvasList = []
            divideHeights.forEach((yCoord,index)=>{
                const newCanvas = document.createElement('canvas');
                let lastHeight = 0
                if (divideHeights[index -1]) lastHeight = divideHeights[index -1]

                
                newCanvas.width = canvas.width;
                newCanvas.height = yCoord - lastHeight;
                const newCtx = newCanvas.getContext('2d');
                
                // console.log(newCanvas.height);

                const startX = 0;
                const startY = lastHeight;

                const width = newCanvas.width;
                const height = newCanvas.height;

                newCtx.drawImage(canvas, startX, startY, width, height,0,0,width,height);
                
                // document.body.append(newCanvas)
                // document.body.append('==--==')

                canvasList.push(newCanvas)
            })
            return canvasList
            // document.body.prepend(canvas)
        },
        findBlankSpace(canvas,ctx,height,startHeight = 'vazio'){
            /*
                Para usar:
                this.findBlankSpace(canvas,canvas.getContext('2d'),1400);

                Irá retornar a altura exata onde há uma linha vazia
            */ 

            if (startHeight == 'vazio') {
                startHeight = height
            }

            let imageData = ctx.getImageData(0, height, canvas.width, 1);
            let pixels = imageData.data
            let empty = true;

            for (let i = 0; i < pixels.length; i += 4) {
                // console.log(pixels[i],pixels[i+1],pixels[i+2],pixels[i+3]);
                
                if (pixels[i+3] == 255){
                    empty = false
                }
            }

            if (empty) {
                //console.log('Is Empty');
                //document.body.prepend('Is Empty: '+ height)
                return height
            } else{
                if (height > 0 && startHeight - height < 700) {
                    // return startHeight
                    return this.findBlankSpace(canvas, ctx, height-1,startHeight)
                } else {
                    //document.body.prepend('Not Empty: ')
                    console.log('cant find empty');
                    return startHeight
                }
            }
        },
        responseError(response, fallbackMessage){
            // CRAQUE
            if (response?.data?.message) return this.error(response.data.message.replace('E_AUTHORIZATION_FAILURE: ',''))
            if (response?.data?.errors) {
                if (response.data.errors[0]) {
                    return this.error(response.data.errors[0].message.replace('E_UNAUTHORIZED_ACCESS: Unauthorized access','Faça login novamente para continuar') || fallbackMessage)
                }
            }

            this.error(fallbackMessage)
        },

       
    }
}
